import config from '@/../google-services.json'
import { readAndCompressImage } from 'browser-image-resizer'
import { IAttachmentModel, IBusinessModel, IEventModel, IFeedbackModel, IRequestModel, IUserModel } from '@wp-darts/shared'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions'
import 'firebase/storage'
// import 'firebase/messaging'

export const apiKey = config.apiKey

// Get the firebase instance
export const app = firebase.initializeApp(config)

// Get a Firestore instance
export const db = app.firestore()

// Get a Auth instance
export const auth = app.auth()

// Get a Auth instance
export const storage = app.storage()

// Get a Functions instance
export const functions = app.functions('europe-west3')

// Get a Messaging instance
// export const messaging = app.messaging()

// Define the various collections
export const usersCollectionName = process.env.NODE_ENV === 'production' ? 'users' : 'usersDevelopment'
export const usersCollection = db.collection(usersCollectionName) as firebase.firestore.CollectionReference<IUserModel>
export const userDocument = (id: string): firebase.firestore.DocumentReference<IUserModel> => {
  return usersCollection.doc(id) as firebase.firestore.DocumentReference<IUserModel>
}

// Feedbacks
export const feedbacksCollectionName = process.env.NODE_ENV === 'production' ? 'feedbacks' : 'feedbacksDevelopment'
export const feedbacksCollection = db.collection(feedbacksCollectionName) as firebase.firestore.CollectionReference<IFeedbackModel>
export const feedbacksCollectionGroup = db.collectionGroup(feedbacksCollectionName) as firebase.firestore.CollectionReference<IFeedbackModel>
export const userFeedbackCollection = (userId: string): firebase.firestore.CollectionReference<IFeedbackModel> => {
  return userDocument(userId).collection(feedbacksCollectionName) as firebase.firestore.CollectionReference<IFeedbackModel>
}
export const feedbacksDocument = (id: string): firebase.firestore.DocumentReference<IFeedbackModel> => {
  return feedbacksCollection.doc(id) as firebase.firestore.DocumentReference<IFeedbackModel>
}

// Businesses 
export const businessesCollectionName = process.env.NODE_ENV === 'production' ? 'businesses' : 'businessesDevelopment'
export const businessesCollection = db.collection(businessesCollectionName) as firebase.firestore.CollectionReference<IBusinessModel>
export const businessDocument = (id: string): firebase.firestore.DocumentReference<IBusinessModel> => {
  return businessesCollection.doc(id) as firebase.firestore.DocumentReference<IBusinessModel>
}

// Events
export const businessEventsCollectionName = process.env.NODE_ENV === 'production' ? 'events' : 'eventsDevelopment'
export const businessEventsCollection = (businessId: string): firebase.firestore.CollectionReference<IEventModel> => {
  return businessDocument(businessId).collection(businessEventsCollectionName) as firebase.firestore.CollectionReference<IEventModel>
}
export const businessEventsDocument = (businessId: string, id: string): firebase.firestore.DocumentReference<IEventModel> => {
  return businessEventsCollection(businessId).doc(id) as firebase.firestore.DocumentReference<IEventModel>
}

// Requests
export const requestsCollectionName = process.env.NODE_ENV === 'production' ? 'requests' : 'requestsDevelopment'
export const requestsCollection = db.collection(requestsCollectionName) as firebase.firestore.CollectionReference<IRequestModel>
export const userRequestCollection = (userId: string): firebase.firestore.CollectionReference<IRequestModel> => {
  return userDocument(userId).collection(requestsCollectionName) as firebase.firestore.CollectionReference<IRequestModel>
}
export const requestsDocument = (userId: string, id: string): firebase.firestore.DocumentReference<IRequestModel> => {
  return userRequestCollection(userId).doc(id) as firebase.firestore.DocumentReference<IRequestModel>
}

// Define the callable functions
export const completeUserCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'completeUserCallable' : 'development-completeUserCallable',
)
export const deleteLocationCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'deleteLocationCallable' : 'development-deleteLocationCallable',
)
export const deleteUserCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'deleteUserCallable' : 'development-deleteUserCallable',
)
export const performSubscriptionCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'performSubscriptionCallable' : 'development-performSubscriptionCallable',
)
export const createGatewaySessionCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'createGatewaySessionCallable' : 'development-createGatewaySessionCallable',
)
export const cancelSubscriptionCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'cancelSubscriptionCallable' : 'development-cancelSubscriptionCallable',
)
export const claimLocationCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'claimLocationCallable' : 'development-claimLocationCallable',
)
export const createNewLocationOwnerUserCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'createNewLocationOwnerUserCallable' : 'development-createNewLocationOwnerUserCallable',
)
export const cancelLocationClaimCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'cancelLocationClaimCallable' : 'development-cancelLocationClaimCallable',
)
export const translateTextCallable = functions.httpsCallable(
  process.env.NODE_ENV === 'production' ? 'translateTextCallable' : 'development-translateTextCallable',
)

// Define the storage folders
export const businessesFolder = storage.ref(
  process.env.NODE_ENV === 'production' ? 'businesses' : 'businessesDevelopment'
)
export const businessesDocumentsFolder = (businessId: string): firebase.storage.Reference => storage.ref(
  `${process.env.NODE_ENV === 'production' ? 'businesses' : 'businessesDevelopment'}/${businessId}`
)
export const eventsFolder = storage.ref(
  process.env.NODE_ENV === 'production' ? 'events' : 'eventsDevelopment'
)
export const eventsDocumentsFolder = (businessId: string, eventId: string): firebase.storage.Reference => storage.ref(
  `${process.env.NODE_ENV === 'production' ? 'businesses' : 'businessesDevelopment'}/${businessId}/${process.env.NODE_ENV === 'production' ? 'events' : 'eventsDevelopment'}/${eventId}`
)
export const uploadDocument = async (destination: firebase.storage.Reference, file: File, document: IAttachmentModel): Promise<string> => {

  const resizedImage = await readAndCompressImage(file, {
    quality: 0.7,
    width: 1000,
    height: 1000,
    autoRotate: false,
    mimeType: 'image/jpeg'
  })
  console.debug('uploadDocument', { image: resizedImage })

  return new Promise((resolve, reject) => {
    const uploadTask = destination
      .child(document.name)
      .put(resizedImage)

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed',
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log('Upload is ' + progress + '% done')
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused')
              break
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running')
              break
          }
        },
        (error): void => {
          // Handle unsuccessful uploads
          console.log('Upload failed', error)
          reject(error)
        },
        async () => {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          const downloadUrl = await uploadTask.snapshot.ref.getDownloadURL()
          console.log('File available at', downloadUrl)
          resolve(downloadUrl)
        }
    )
  })
}
