import store from '@/store'
import { UserRole } from '@wp-darts/shared'
import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const redirectToProperSectionGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<any> => {
  console.debug('redirectToProperSectionGuard', { from, to })

  if (!store.getters['auth/isAuthenticated']) {
    console.debug('roleGuard - unauthorized - not authenticated')

    next({ name: 'Login', query: { redirect: to.fullPath } } as any)
    return
  }
  
  if (!store.state.auth || !store.state.auth.user) {
    console.debug('redirectToProperSectionGuard - unauthorized - user not fetched yet')
  
    next({ name: 'Login', query: { redirect: to.fullPath } } as any)
    return
  }

  if(to.meta.role != undefined && store.state.auth.user.role != undefined && store.state.auth.user.role.valueOf() < UserRole.USER) {
    console.debug('redirectToProperSectionGuard - unauthorized - user permission invalid')

    next({ name: 'Login', query: { redirect: to.fullPath } } as any)
    return
  }

  // Redirect using the domain
  if (window.location.origin.includes('admin.wpdarts.com')) {
    next({ path: '/admin', query: { redirect: to.fullPath } } as any)
    return
  } else if (window.location.origin.includes('manager.wpdarts.com')) {
    next({ path: '/manager', query: { redirect: to.fullPath } } as any)
    return
  }

  if(store.state.auth.user.role.valueOf() < UserRole.ADMIN) {
    console.debug('redirectToProperSectionGuard - authorized - redirect to manager')

    next({ path: '/manager', query: { redirect: to.fullPath } } as any)
  } else {
    console.debug('redirectToProperSectionGuard - authorized - redirect to admin')

    next({ path: '/admin', query: { redirect: to.fullPath } } as any)
  }
}
