import { Module } from 'vuex'
import { IAuthenticationStoreState } from '../interfaces'
import { auth } from '@/utilities'
import firebase from 'firebase'
import { IBusinessModel, IRequestModel, IUserModel, StatusCode } from '@wp-darts/shared'
import { firestoreAction } from 'vuexfire'
import { businessDocument, businessesCollection, cancelLocationClaimCallable, claimLocationCallable, completeUserCallable, requestsCollection, userDocument, userRequestCollection } from '@/utilities/firebase.utility'
import algoliasearch from 'algoliasearch'
import moment from 'moment'
import { v4 as UUID } from 'uuid'

export const AuthenticationStore: Module<IAuthenticationStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    authUser: undefined,
    userBusiness: undefined,
    userBusinesses: [],
    userBusinessRequests: [],
    user: undefined,
    algoliaClient: undefined,
  },

  getters: {
    getAuthUser (state): firebase.User | undefined | null {
      return state.authUser
    },
    getUser (state): IUserModel | undefined | null {
      return state.user
    },
    isAuthenticated (state): boolean {
      return typeof state.authUser === 'object'
    },
    isBusinessSubscriptionPaymentStillValid (state): boolean {
      if (state.userBusiness == undefined || state.userBusiness.subscription == undefined) {
        return false
      }

      if ((state.userBusiness.subscription.status as any) === 'active') { return true }
      if (
        state.userBusiness.subscription.current_period_end != undefined
        && moment(state.userBusiness.subscription.current_period_end).isAfter(moment())
      ) {
        return true
      }

      return false
    },
    isBusinessSubscriptionCancellable (state): boolean {
      return state.userBusiness != undefined
        && state.userBusiness.subscription != undefined
        && ((state.userBusiness.subscription.status as any) === 'active' || (state.userBusiness.subscription.status as any) === 'unpaid' || (state.userBusiness.subscription.status as any) === 'trialing' || (state.userBusiness.subscription.status as any) === 'incomplete')
    }
  },

  mutations: {
    setAuthUser (state, payload) {
      console.debug('AuthenticationStore - setAuthUser', { state, payload })
      state.authUser = payload
    },
    setUser (state, user) {
      console.log('AuthenticationStore - setUser', user)
      state.user = user
    },
    setAlgoliaClient (state, algoliaClient) {
      console.log('AuthenticationStore - setAlgoliaClient', algoliaClient)
      state.algoliaClient = algoliaClient
    }
  },

  actions: {
    // MARK: Initializations
    async initializeAlgoliaClient ({ state, commit }) {
      console.debug('AuthenticationStore - initializeAlgoliaClient')
      
      if (state.user == undefined || state.user.algoliaPublicKey == undefined) {
        console.debug('AuthenticationStore - initializeAlgoliaClient - user or public key not valid')
        return
      }

      commit('setAlgoliaClient', algoliasearch('FSESOWM22Z', state.user.algoliaPublicKey))
    },
    async deinitializeAlgoliaClient ({ commit }) {
      console.debug('AuthenticationStore - deinitializeAlgoliaClient')
      commit('setAlgoliaClient', undefined)
    },

    // Vuex mutations
    bindUserDocument: firestoreAction(async ({ state, bindFirestoreRef }) => {
      console.debug('AuthenticationStore - bindUserDocument', { id: state.authUser ? state.authUser.uid : undefined })
      if (state.authUser == undefined || state.authUser.uid == undefined) {
        return
      }

      await bindFirestoreRef('user', userDocument(state.authUser.uid))
    }),
    unbindUserDocument: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindUserDocument')
      unbindFirestoreRef('user')
    }),

    bindUserBusinessDocument: firestoreAction(async ({ state, bindFirestoreRef }, businessId: string) => {
      console.debug('AuthenticationStore - bindUserBusinessDocument')

      if (state.user == undefined || businessId == undefined) {
        return
      }

      await bindFirestoreRef('userBusiness', businessDocument(businessId), { reset: true, wait: true })
    }),
    unbindUserBusinessDocument: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindUserBusinessDocument')
      unbindFirestoreRef('userBusiness')
    }),

    bindUserBusinessesDocuments: firestoreAction(async ({ state, bindFirestoreRef }) => {
      console.debug('AuthenticationStore - bindUserBusinessesDocuments')

      if (state.user == undefined) {
        console.debug('AuthenticationStore - bindUserBusinessesDocuments - user is undefined')
        
        return
      }
      console.debug('AuthenticationStore - bindUserBusinessesDocuments - user is defined', { user: state.user })

      await bindFirestoreRef('userBusinesses', businessesCollection.where('ownerId', '==', state.user.id), { reset: true, wait: true })
    }),
    unbindBusinessesDocuments: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindBusinessesDocuments')
      unbindFirestoreRef('userBusinesses')
    }),

    bindUserBusinessesRequestDocuments: firestoreAction(async ({ state, bindFirestoreRef }) => {
      console.debug('AuthenticationStore - bindUserBusinessesRequestDocuments')

      if (state.user == undefined) {
        return
      }

      await bindFirestoreRef('userBusinessRequests', userRequestCollection(state.user.id), { reset: true, wait: true })
    }),
    unbindUserBusinessesRequestDocuments: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindUserBusinessesRequestDocuments')
      unbindFirestoreRef('userBusinessRequests')
    }),

    // Authentication actions
    async signIn ({ commit }, params: { email: string, password: string }): Promise<boolean> {
      console.debug('AuthenticationStore - signIn')
      try {
        const result = await auth
          .signInWithEmailAndPassword(params.email, params.password)
        
        if (result.user) {
          commit('setAuthUser', result.user)
        }

        console.debug('AuthenticationStore - signIn - completed', { result })
      } catch (error) {
        console.error('AuthenticationStore - signIn error', { params, error })

        return false
      }

      return true
    },

    async signOut ({ commit, dispatch }): Promise<boolean> {
      console.debug('AuthenticationStore - signOut')
      try {
        await auth.signOut()
        
        commit('setAuthUser', undefined)
        commit('setUser', undefined)
        commit('setAlgoliaClient', undefined)
        dispatch('unbindBusinessDocument')
        
        console.debug('AuthenticationStore - signOut - completed')
        return true
      } catch (error) {
        // commit("setError", error.message);
        console.error('AuthenticationStore - signOut - error', { error })
      }

      return false
    },

    async emailSignUp(_ ,params: { name: string, surname: string, email: string; password: string }): Promise<boolean> {
      console.debug('AuthenticationStore - emailSignUp', { email: params.email })

      // Authenticate Actual User
      try {
        const credential = await auth.createUserWithEmailAndPassword(params.email, params.password)
        console.debug('AuthenticationStore - emailSignUp - created', { credentialUser: credential.user })
      } catch (error) {
        console.debug('AuthenticationStore - emailSignUp - error signing in', { error })
        console.debug(error)

        return false
      }

      const timeout = (ms: number) => { return new Promise(resolve => setTimeout(resolve, ms)); }
      await timeout(2000);

      if (auth.currentUser == undefined) {
        console.debug('AuthenticationStore - emailSignUp - user is null')

        return false
      }

      const functionParams = {source: 'manager', name: params.name, surname: params.surname, email: params.email, password: params.password}

      // Function User Callable
      try {
        const result = await completeUserCallable(functionParams)
        console.debug('AuthenticationStore - emailSignUp - result', { result })

        if (!result || result.data.code !== StatusCode.OK) {
          return false
        }
      } catch (error) {
        console.debug('AuthenticationStore - emailSignUp - error', { error })
        console.debug(error)

        return false
      }

      return true
    },
  
    async completeUser({ state }, params: { name: string, surname: string }): Promise<boolean> {
      console.debug('AuthenticationStore - completeUser', { params })

      if (state.authUser == undefined || state.user == undefined) {
        console.debug('AuthenticationStore - completeUser - user is null')

        return false
      }

      // Function User Callable
      try {
        const result = await completeUserCallable({ ...params, source: 'manager' })
        console.debug('AuthenticationStore - completeUser - result', { result })

        if (!result || result.data.code !== StatusCode.OK) {
          return false
        }
      } catch (error) {
        console.debug('AuthenticationStore - completeUser - error', { error })
        console.debug(error)

        return false
      }

      return true
    },

    async sendPasswordRecoveryEmail (_, params: { email: string }): Promise<boolean> {
      console.debug('AuthenticationStore - sendPasswordRecoveryEmail')
      try {
        await auth.sendPasswordResetEmail(params.email)

        console.debug('AuthenticationStore - sendPasswordRecoveryEmail - completed')
      } catch (error) {
        console.error('AuthenticationStore - sendPasswordRecoveryEmail error', { params, error })

        return false
      }

      return true
    },

    // Business related actions

    updateUserBusiness: firestoreAction(async (_, business: Partial<IBusinessModel>): Promise<boolean> => {
      console.debug('AuthenticationStore - updateUserBusiness', { business })
      if (business == undefined || business.id == undefined) { return false }

      // Set the first image as main if there is no one
      if (
        business.attachments != undefined
        && typeof business.attachments === 'object'
        && Object.keys(business.attachments).length > 0
        && Object.values(business.attachments).find(a => a.isMain === true) === undefined
      ) {
        const first = Object.values(business.attachments)
          .sort((a,b) => a.createdTs - b.createdTs)[0]

        for (const k of Object.keys(business.attachments)) {
          business.attachments[k].isMain = k === first.id
        }
      }

      try {
        await businessesCollection.doc(business.id).update(business)
        console.debug('AuthenticationStore - updateBusiness - success')

        return true
      } catch (error) {
        console.error('AuthenticationStore - updateBusiness - error updating a business', { error })
        console.error(error)

        return false
      }
    }),


    claimLocation: firestoreAction(async ({ state }, businessId: string): Promise<boolean> => {
      console.debug('BusinessesStore - claimLocation', { businessId })
      if (businessId == undefined || state.user == undefined) { return false }

      try {
        const response: { data: { code: StatusCode }} = await claimLocationCallable(businessId)
        if (response.data.code !== StatusCode.OK) {
          // TODO: Show error
          return false
        }
        
        return true
      } catch (error) {
        console.error('BusinessesStore - claimLocation - error updating a business', { error })
        console.error(error)

        return false
      }
    }),

    cancelLocationClaim: firestoreAction(async ({ state }): Promise<boolean> => {
      console.debug('BusinessesStore - cancelLocationClaim')
      if (state.user == undefined) { return false }

      try {
        const response: { data: { code: StatusCode }} = await cancelLocationClaimCallable()
        if (response.data.code !== StatusCode.OK) {
          return false
        }
   
        return true
      } catch (error) {
        console.error('BusinessesStore - cancelLocationClaim - error updating a business', { error })
        console.error(error)

        return false
      }
    }),

    // Request related actions
    createNewLocationRequest: firestoreAction(async ({ state }, request: Partial<IRequestModel>): Promise<boolean> => {
      console.debug('BusinessesStore - createNewLocationRequest', { request })
      if (state.authUser == undefined || state.user == undefined || state.user.businessId != undefined) { return false }

      try {
        const id = UUID()
        await userRequestCollection(state.authUser.uid)
          .doc(id)
          .set({
            ...request,
            id,
            businessId: state.authUser.uid
          } as IRequestModel)
  
        return true
      } catch (error) {
        console.error('BusinessesStore - createNewLocationRequest - creating the request', { error })
        console.error(error)

        return false
      }
    }),
  }
}
