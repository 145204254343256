
import { defineComponent } from "vue";
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength, sameAs, maxLength } from "@vuelidate/validators";
import DartsLogo from "@/components/DartsLogo.vue";
import CheckBox from "@/components/CheckBox.vue";
import BackGround from "@/components/BackGround.vue";
import { createNamespacedHelpers } from "vuex";
import CustomLoader from "@/components/CustomLoader.vue";
import { AlertType } from "@/enums";

const authStoreHelper = createNamespacedHelpers('auth')

const nameMinLength = 2
const nameMaxLength = 200
const surnameMinLength = 2
const surnameMaxLength = 200
const passwordMinLength = 5
const passwordMaxLength = 200

export default defineComponent({
  name: "SignUpPage",

  components: {
    DartsLogo,
    CheckBox,
    BackGround,
    CustomLoader,
  },

  data() {
    return {
      name: '',
      surname: '',
      email: '',
      password: '',
      passwordConfirm: '',
      termsAndConditions: false,
      isSeePassword: false,
      isSeePasswordConfirmation: false,
      passwordFieldTypePassword: "password",
      passwordFieldTypePasswordConfirmation: "password",
      isLoading: false,
      isTermsAndConditionsChecked: false,

      // Constants
      nameMinLength, nameMaxLength, surnameMinLength,
      surnameMaxLength, passwordMinLength, passwordMaxLength
    };
  },

  setup () {
    return { v$: useVuelidate() }
  },

  methods: {
    ...authStoreHelper.mapActions(['emailSignUp']),
    ...authStoreHelper.mapActions(['signIn']),

    seeHidePassword: function () {
      this.isSeePassword = !this.isSeePassword;
      this.passwordFieldTypePassword =
        this.passwordFieldTypePassword === "password" ? "text" : "password";
    },
    seeHidePasswordConfirmation: function () {
      this.isSeePasswordConfirmation = !this.isSeePasswordConfirmation;
      this.passwordFieldTypePasswordConfirmation =
        this.passwordFieldTypePasswordConfirmation === "password"
          ? "text"
          : "password";
    },
    passwordRecovery: function () {
      console.log("Recover Password");
    },
    goBack: function () {
      this.$router.go(-1);
    },
    onCheckBox: function () {
      this.termsAndConditions = !this.termsAndConditions;
      this.isTermsAndConditionsChecked = !this.isTermsAndConditionsChecked
    },

    async onSignUp() {
      console.log('SignUpPage - OnSignUp')
      this.isLoading = true

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        console.error('Auth not performed due to validation error', { errors: this.v$.$errors })
        this.isLoading = false
        return
      }

      const resultSignUp = await this.emailSignUp({name: this.name, surname: this.surname, email: this.email, password: this.password })
      console.debug('SignUpPage - onFormSubmit - done', { resultSignUp })

      const resultSignIn = await this.signIn({ email: this.email, password: this.password })
      console.debug('SignUpPage - resultSignIn - done', { resultSignIn })


      if (resultSignUp !== true && resultSignIn === true) {
        console.debug('performLogin - ok')
        await this.$store.dispatch('alerts/create', { message: this.$t('sign-up-page.response.already-exists'), type: AlertType.warning })
        await this.$store.dispatch('alerts/create', { message: this.$t('sign-up-page.response.ok'), type: AlertType.success })
        await this.$store.dispatch('alerts/create', { message: this.$t('sign-up-page.response.complete-profile'), type: AlertType.info })
        this.$router.push(`/manager/dashboard`);
      } else if (resultSignUp === true && resultSignIn === true) {
        await this.$store.dispatch('alerts/create', { message: this.$t('sign-up-page.response.creation-ok'), type: AlertType.success })
        this.$router.push(`/manager/dashboard`);
      } else if (resultSignUp === true && resultSignIn !== true) {
        await this.$store.dispatch('alerts/create', { message: this.$t('sign-up-page.response.creation-failed'), type: AlertType.error })
        console.error('auth error')
      } else if (resultSignUp !== true && resultSignIn !== true) {
        await this.$store.dispatch('alerts/create', { message: this.$t('sign-up-page.response.already-exists'), type: AlertType.error })
        console.error('auth error')
      }

      this.isLoading = false
    },
  },

  validations () {
    return {
      name: { required, minLength: minLength(nameMinLength), maxLength: maxLength(nameMaxLength) },
      surname: { required, minLength: minLength(surnameMinLength), maxLength: maxLength(surnameMaxLength) },
      email: { required, email },
      password: { required, minLength: minLength(passwordMinLength), maxLength: maxLength(passwordMaxLength) },
      passwordConfirm: { required, match: sameAs(this.password) },
      termsAndConditions: { required: (value: boolean) => value === true },
    }
  }
});
