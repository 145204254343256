import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginPage from '../views/LoginPage.vue'
import { redirectToProperSectionGuard, roleGuard } from '@/guards'
import { UserRole } from '@wp-darts/shared'
import SignUpPage from '../views/SignUpPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth/login',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/auth/signup',
    name: 'SignUp',
    component: SignUpPage
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AdminDashboard.vue'),
    beforeEnter: roleGuard,
    meta: { role: UserRole.ADMIN },
    children: [
      {
        path: '/admin/users',
        name: 'Admin Users',
        component: () => import('../views/AdminUsers.vue')
      },
      {
        path: '/admin/locations',
        name: 'Admin Places',
        component: () => import('../views/AdminLocationsView.vue')
      },
      {
        path: '/admin/locations/:id',
        name: 'Admin Place',
        component: () => import('../views/AdminLocationView.vue')
      },
      {
        path: '/admin/requests',
        name: 'Admin Requests',
        component: () => import('../views/AdminRequests.vue')
      },
      {
        path: '/admin/feedbacks',
        name: 'Admin Feedbacks',
        component: () => import('../views/AdminFeedbacks.vue')
      },
      {
        path: '/admin/locations/:id',
        name: 'Admin Place',
        component: () => import('../views/AdminLocationView.vue')
      },
      { path: '', redirect: '/admin/users' },
      { path: '/admin/:pathMatch(.*)*', redirect: '/admin/users' }
    ]
  },
  {
    path: '/manager',
    name: 'Manager',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManagerView.vue'),
    beforeEnter: roleGuard,
    meta: { role: UserRole.USER },
    children: [
      {
        path: '/manager/dashboard',
        name: 'Manager Dashboard',
        component: () => import('../views/ManagerDashboard.vue')
      },
      {
        path: '/manager/dashboard/locations',
        name: 'Manager Places',
        component: () => import('../views/ManagerLocationsView.vue'),
      },
      {
        path: '/manager/dashboard/locations/shop|place',
        name: 'Manager Shop or Places',
        component: () => import('../views/ManagerLocationsView.vue'),
      },
      {
        path: '/manager/dashboard/locations/:id',
        alias: ['/manager/dashboard/locations/:id/success', '/manager/dashboard/locations/:id/canceled'],
        name: 'Manager Place',
        component: () => import('../views/ManagerLocationView.vue')
      },
      {
        path: '/manager/dashboard/locations/new',
        name: 'New Manager Place',
        component: () => import('../views/ManagerLocationView.vue')
      },
      { path: '', redirect: '/manager/dashboard' },
    ]
  },
  { path: '/:pathMatch(.*)*', beforeEnter: redirectToProperSectionGuard } as any
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
