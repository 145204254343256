
import { AlertType } from '@/enums';
import useVuelidate from '@vuelidate/core'
import { email, required } from '@vuelidate/validators';
import { defineComponent } from 'vue'
import { createNamespacedHelpers } from 'vuex';

const authHelper = createNamespacedHelpers('auth')


export default defineComponent({
  name: 'PasswordRecoveryModal',

  emits: ['close'],

  data() {
    return {
      email: '',
      isLoading: false
    }
  },

  methods: {
    ...authHelper.mapActions(['sendPasswordRecoveryEmail']),

    async onSend (): Promise<void> {
      this.isLoading = true
      console.debug('PasswordRecoveryModal - onSend')

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        console.error('Auth not performed due to validation error', { errors: this.v$.$errors })
        this.isLoading = false
        
        return
      }

      const result = await this.sendPasswordRecoveryEmail({ email: this.email })
      if (result === true) {
        console.debug('performLogin - ok')
        await this.$store.dispatch('alerts/create', { message: this.$t('password-recovery-modal.response.ok'), type: AlertType.success })
      } else {
        await this.$store.dispatch('alerts/create', { message: this.$t('password-recovery-modal.response.error'), type: AlertType.error })
        console.error('auth error')
      }

      this.isLoading = false
    }
  },

  setup () {
    return { v$: useVuelidate() }
  },

  validations () {
    return {
      email: { required, email },
    }
  }
})

