
import { defineComponent } from 'vue';
import DartsLogo from '@/components/DartsLogo.vue'
import BackGround from '@/components/BackGround.vue'
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength, maxLength } from '@vuelidate/validators';
import PasswordRecoveryModal from '@/components/dialogs/PasswordRecoveryModal.vue'
import { createNamespacedHelpers } from 'vuex';
import { promiseTimeout } from '@/utilities'
import { AlertType } from '@/enums';

const authenticationStoreHelper = createNamespacedHelpers('auth')

const passwordMinLength = 5
const passwordMaxLength = 200

export default defineComponent({
  name: 'LoginPage',

  components: {
    DartsLogo, BackGround, PasswordRecoveryModal
  },

  setup () {
    return { v$: useVuelidate() }
  },

  computed: {
    ...authenticationStoreHelper.mapState(['user']),
    isAdmin (): boolean {
      return window.location.origin.includes('admin.wpdarts.com')
    }
  },
  
  data() {
    return {
      email: '',
      password: '',
      isSeePassword: false,
      passwordFieldType: 'password',
      isShowPasswordRecovery: false,
      isLoading: false,

      // Constants
      passwordMinLength, passwordMaxLength
    }
  },

  methods: {
    ...authenticationStoreHelper.mapActions(['signOut', 'signIn', 'isUserAdmin']),

    seeHidePassword: function () {
      this.isSeePassword = !this.isSeePassword
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    onPasswordRecovery: function () {
      console.log('Recover Password')
      this.isShowPasswordRecovery = true
    },
    goSignUp: function () {
      this.$router.push({ path: '/auth/signup' })
    },
    async performLogin (): Promise<void> {
      this.isLoading = true
      await promiseTimeout(500)
      console.debug('performLogin')

      // Validate the fields
      this.v$.$touch()
      if (this.v$.$error) {
        console.error('Auth not performed due to validation error', { errors: this.v$.$errors })
        this.isLoading = false
        
        return
      }

      const result = await this.signIn({ email: this.email, password: this.password })
      if (result === true) {
        // await this.$store.dispatch('alerts/create', { message: 'autenticazione riuscita', type: AlertType.success })
        console.debug('performLogin - ok')
        await this.$store.dispatch('alerts/create', { message: this.$t('login-page.response.ok'), type: AlertType.success })
      } else {
        await this.$store.dispatch('alerts/create', { message: this.$t('login-page.response.error'), type: AlertType.error })
        console.error('auth error')
      }

      this.isLoading = false
    }
  },

  validations () {
    return {
      email: { required, email },
      password: { required, minLength: minLength(passwordMinLength), maxLength: maxLength(passwordMaxLength) }
    }
  }
});
