import { AlertType } from '@/enums'
import { IAlert } from '@/interfaces'
import { ActionContext, Module } from 'vuex'

export interface IAlertsStoreState {
  alerts: IAlert[]
}

export const AlertsStore: Module<IAlertsStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    alerts: [] as IAlert[]
  },

  mutations: {
    add (state, payload: IAlert) {
      console.debug('AlertsStore - add', { state, payload })

      if (state.alerts === undefined) {
        state.alerts = []
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      state.alerts!.push(payload)

      setTimeout(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        state.alerts! = state.alerts!.filter(v => v.ts !== payload.ts)
      },
      3000)
    }
  },

  actions: {
    create (
      context: ActionContext<IAlertsStoreState, unknown>,
      params: { message: string, type: AlertType }
    ): void {
      console.debug('AlertsStore - create', { context, params })

      // unsubscribe can be called to stop listening for changes
      const alert: IAlert = {
        ts: (new Date()).valueOf() / 1000,
        message: params.message,
        type: params.type
      }

      context.commit('add', alert)
    }
  }
}
