import { IAddressModel } from '@wp-darts/shared';

export class GoogleMapsUtility {

  public static fieldsMap = {
    administrativeAreaLevel1: 'administrative_area_level_1',
    administrativeAreaLevel2: 'administrative_area_level_2',
    country: 'country',
    locality: 'locality',
    postalCode: 'postal_code',
    route: 'route',
    streetNumber: 'street_number',
  }

  public static searchResultToIAddressModel(result: any): IAddressModel | undefined {
    if (
      result == undefined
      || typeof result !== 'object'
      || result.formatted_address == undefined
      || typeof result.formatted_address !== 'string'
      || result.geometry == undefined
      || result.geometry.location == undefined
    ) {
      return undefined
    }

    const addressModel = {
      formattedAddress: result.formatted_address,
      latitude: result.geometry.location.lat(),
      longitude: result.geometry.location.lng(),
    } as IAddressModel

    if (
      result.address_components != undefined
      && typeof result.address_components === 'object'
      && Array.isArray(result.address_components)
    ) {
      for (const entry of Object.entries(GoogleMapsUtility.fieldsMap)) {
        console.debug('entry', { entry })
        const idx = (result.address_components as any[])
          .findIndex((c: { long_name?: string; types?: string[] }) =>
            c.long_name != undefined
            && typeof c.long_name === 'string'
            && c.types != undefined
            && typeof c.types === 'object'
            && Array.isArray(c.types)
            && c.types.includes(entry[1])
          )
  
        if (idx !== -1) {
          addressModel[entry[0]] = result.address_components[idx].long_name
        }
      }
    }

    return addressModel
  }
}
