import store from '@/store'
import { UserRole } from '@wp-darts/shared'
import { NavigationGuard, NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

export const roleGuard: NavigationGuard = async (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): Promise<any> => {
  console.debug('roleGuard', { from, to, role: to.meta.role })

  if (!store.getters['auth/isAuthenticated']) {
    console.debug('roleGuard - unauthorized - not authenticated')

    next({ name: 'Login', query: { redirect: to.fullPath } } as any)
    return
  }
  
  if (!store.state.auth || !store.state.auth.user) {
    console.debug('roleGuard - unauthorized - user not fetched yet')
  
    next({ name: 'Login', query: { redirect: to.fullPath } } as any)
    return
  }

  if(to.meta.role != undefined && store.state.auth.user.role != undefined && store.state.auth.user.role.valueOf() < (to.meta.role as UserRole).valueOf()) {
    console.debug('roleGuard - unauthorized - user permission invalid', { user: store.state.auth.user.role.valueOf(), required: (to.meta.role as UserRole).valueOf() })

    next({ name: 'Login', query: { redirect: to.fullPath } } as any)
    return
  }

  console.debug('roleGuard - authorized')

  next()
}
