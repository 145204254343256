
import { IUserModel, UserRole } from '@wp-darts/shared';
import { defineComponent } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import AlertComponent from '@/components/AlertComponent.vue'
import store from './store';
import { auth } from './utilities'

const authHelper = createNamespacedHelpers('auth')
const alertsHelper = createNamespacedHelpers('alerts')

export default defineComponent({
  name: 'App',

  store: store,

  components: { AlertComponent },

  computed: {
    ...authHelper.mapState(['user', 'algoliaClient']),
    ...alertsHelper.mapState(['alerts'])
  },

  methods: {
    // Include the AuthenticationStore actions
    ...authHelper.mapActions(['bindAuthUser', 'initializeAlgoliaClient']),
  },
  
  mounted () {
    // Bind and listen the Firebase Auth authentication status
    console.debug('App - mounted')

    auth
      .onAuthStateChanged(async (authUser) => {
        console.debug('App - onAuthStateChanged - received value', { authUser })
        if (authUser && typeof authUser === 'object') {
          this.$store.commit('auth/setAuthUser', authUser)

          const user: IUserModel = await this.$store.dispatch('users/getUser', authUser.uid)
          console.debug('App - onAuthStateChanged - user', { authUser, user })

          this.$store.commit('auth/setUser', user)
          this.$store.dispatch('auth/bindUserDocument')
          this.initializeAlgoliaClient()

          // eslint-disable-next-line eqeqeq
          if (user == undefined) {
            return
          }
          if (user.role < UserRole.USER) {
            this.$store.dispatch('auth/deinitializeAlgoliaClient')
            this.$store.dispatch('auth/unbindUserDocument')
            this.$store.commit('auth/setAuthUser', null)
            this.$store.commit('auth/setUser', null)
            this.$router.replace({ name: 'Login' })

            await auth.signOut()

            return
          }

          // eslint-disable-next-line eqeqeq
          if (this.$route.query != undefined && this.$route.query.redirect != undefined) {
            console.debug('App - onAuthStateChanged - redirect', { redirect: this.$route.query.redirect })
          
            this.$router.replace({ path: this.$route.query.redirect as string })
          } else {
            console.debug('App - onAuthStateChanged - redirect to /')
          
            this.$router.replace({ path: '/' })
          }
        } else {
          this.$store.dispatch('auth/deinitializeAlgoliaClient')
          this.$store.dispatch('auth/unbindUserDocument')
          this.$store.commit('auth/setAuthUser', null)
          this.$store.commit('auth/setUser', null)
        }
      })
  },

  watch: {
    user (user: IUserModel | undefined) {
      if (user != undefined && user.algoliaPublicKey != undefined && this.algoliaClient == undefined) {
        this.initializeAlgoliaClient()
      }
    }
  }
});
