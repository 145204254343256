import { BusinessTypeUtility, IBusinessModel, IEventModel, PlatformType } from '@wp-darts/shared';
import { Module } from 'vuex';
import { IBusinessesStoreState } from '@/interfaces/businesses-store-state.interface';
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import { businessesIndex } from '@/utilities';
import { businessesCollection } from '@/utilities';
import { businessDocument, businessEventsCollection, businessEventsDocument } from '@/utilities/firebase.utility';
import { v4 as UUID } from 'uuid'
import moment, { Moment } from 'moment'
import * as XLSX from 'xlsx'
import { IStoreState } from '@/interfaces';

const hitsPerPage = 15

export const BusinessesStore: Module<IBusinessesStoreState, unknown> = {
  namespaced: true,

  state: {
    business: undefined as IBusinessModel | undefined,
    businesses: [] as IBusinessModel[],
    businessesPage: 0,
    businessesPaginationEnd: false,
    businessesSearchQuery: '',
    businessesTotal: 0,
    events: [] as IEventModel[],
  },

  mutations: {
    ...vuexfireMutations,
    setBusinessesSearchQuery (state, businessesSearchQuery) {
      state.businessesSearchQuery = businessesSearchQuery
    },
    resetBusinesses (state, { businesses, page, end, total }) {
      state.businessesSearchQuery = ''
      state.businesses = businesses
      state.businessesPage = page
      state.businessesPaginationEnd = end
      state.businessesTotal = total
    },
    setBusinesses (state, { businesses, page, end, total }) {
      console.log('BusinessesStore - setBusinesses', { businesses, page, end, total })
      state.businesses = businesses
      state.businessesPage = page
      state.businessesPaginationEnd = end
      state.businessesTotal = total
    },
    setBusinessEvents (state, { events }) {
      state.events = events
    },
  },

  actions: {
    // Vuex mutations
    bindBusinessDocument: firestoreAction((context, id: string) => {
      console.debug('AuthenticationStore - bindBusinessDocument', { id })

      return context.bindFirestoreRef('business', businessDocument(id))
    }),
    unbindBusinessDocument: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindUserRef')
      unbindFirestoreRef('business')
    }),
  
    async reloadBusinesses (
      { rootState, commit },
      params?: {
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number
      }
    ): Promise<boolean> {
      console.log('BusinessesStore - reloadBusinesses', { params })
  
      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('BusinessesStore - reloadBusinesses - algolia clinet undefined')

        return false
      }

      try {
        const result = await businessesIndex((rootState as any).auth.algoliaClient).search(
          '',
          {
            // eslint-disable-next-line eqeqeq
            hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined ? params.facetFilters : undefined,
            cacheable: false
          }
        )
        console.log('BusinessesStore - result', { result })
        commit(
          'setBusinesses',
          {
            page: 0,
            businesses: result.hits,
            end: result.hits.length < result.hitsPerPage,
            total: result.nbHits
          }
        )
      } catch (error) {
        console.error('BusinessesStore - error searching inside the businesses index', { error })
        return false
      }

      return true
    },

    async paginateBusinesses (
      { state, rootState, commit },
      params: {
        forceReload?: boolean,
        query: string
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number,
        sortField?: 'createdTs' | 'type' | 'isEnabled' | 'checkTs' | 'hasOwner' | 'name',
        sortDirection?: 'asc' | 'desc'
      }
    ): Promise<any | undefined> {
      console.debug('BusinessesStore - paginateBusinesses', params)
      commit('setBusinessesSearchQuery', params.query)

      const page = params.query === state.businessesSearchQuery && !params.forceReload
        ? state.businessesPage + 1
        : 0

      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('BusinessesStore - paginateBusinesses - algolia clinet undefined')
        return
      }

      if (params.forceReload !== true && state.businessesPaginationEnd) {
        console.debug('BusinessesStore - paginateBusinesses - pagination ended')
        return
      }

      try {
        const result = await businessesIndex((rootState as any).auth.algoliaClient, params.sortField, params.sortDirection)
          .search(
            params.query,
            {
              page,
              // eslint-disable-next-line eqeqeq
              hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
              // eslint-disable-next-line eqeqeq
              facetFilters: params != undefined ? params.facetFilters : undefined,
              cacheable: params == undefined || params.forceReload !== true
            }
          )
        console.log('BusinessesStore - paginateBusinesses - result', result)
        commit(
          'setBusinesses',
          {
            page,
            businesses: params.forceReload === true ? result.hits : [...state.businesses, ...result.hits],
            end: result.hits.length < result.hitsPerPage,
            total: result.nbHits
          }
        )

        return result
      } catch (error) {
        console.error('BusinessesStore - error searching inside the businesses index', { params, error })
      }

      return undefined
    },

    async instantSearchBusinesses (
      { rootState, commit },
      params: {
        query: string,
        facetFilters?: string | readonly string[] | undefined,
        hitsPerPage?: number | undefined,
        filters?: string | undefined,
        page?: number | undefined
      }
    ): Promise<boolean> {
      console.log('BusinessesStore - instantSearchUsers', params)

      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('BusinessesStore - instantSearchUsers - algolia clinet undefined')

        return false
      }
  
      try {
        const result = await businessesIndex((rootState as any).auth.algoliaClient).search(
          params.query,
          {
            facetFilters: params.facetFilters,
            hitsPerPage: params.hitsPerPage,
            filters: params ? params.filters : undefined,
            page: params.page,
            cacheable: false
          }
        )
        console.log('BusinessesStore - instantSearchUsers - result', result)
        commit(
          'setBusinesses',
          {
            page: params.page,
            businesses: result.hits,
            end: result.hits.length < result.hitsPerPage,
            total: result.nbHits
          }
        )
      } catch (error) {
        console.error('BusinessesStore - instantSearchUsers - error searching inside the users index', { params, error })

        return false
      }

      return true
    },

    async getBusinessLocationEvent (_, params: { businessLocationId: string, eventId: string }): Promise<IEventModel | undefined> {
      console.debug('BusinessesStore - getBusinessLocationEvent', { params })

      try {
        const event = await businessEventsCollection(params.businessLocationId).doc(params.eventId).get()
        console.debug('BusinessesStore - getBusinessLocationEvent - events', { event })

        if (event != undefined) {
          console.debug('BusinessesStore - getBusinessLocationEvent - exists')

          return event.data()
        }
      } catch (error) {
        console.debug('BusinessesStore - getBusinessLocationEvent - error', { error })
        console.debug(error)
      }

      return undefined
    },

    getBusiness: firestoreAction(async ({ commit }, id: string): Promise<IBusinessModel | undefined> => {
      console.debug('BusinessesStore - getBusiness - id: ', { id })

      try {
        const result = (await businessesCollection.doc(id).get()).data()

        console.debug('BusinessesStore - getBusiness - result', result)

        if (result == undefined) { return undefined }

        const business = result as IBusinessModel
        commit('setBusiness', { business: business })
  
        return business
      } catch (error) {
        console.error('BusinessesStore - getBusiness - error getting a working site', { id, error })
        console.error(error)

        return undefined
      }
    }),

    getBusinessByOwnerId: firestoreAction(async (_, ownerId: string): Promise<IBusinessModel[] | undefined> => {
      console.debug('BusinessesStore - getBusinessByOwnerId', { ownerId })

      try {
        let result = undefined
        result = await businessesCollection.where('ownerId', '==', ownerId).get()
        console.log('BusinessesStore - getBusinessByOwnerId - result', result)

        return result.docs.map(e => e.data())
      } catch (error) {
        console.error('BusinessesStore - getBusinessByOwnerId - error getting a working site', { ownerId, error })
        console.error(error)

        return []
      }
    }),

    createBusiness: firestoreAction(async (_, business: IBusinessModel): Promise<boolean> => {
      console.debug('BusinessesStore - createBusiness', { business })
      if (business === undefined) { return false }

      try {
        businessesCollection
          .doc(business.id)
          .set(business)
        console.debug('BusinessesStore - createBusiness - success')

        return true
      } catch (error) {
        console.error('BusinessesStore - createBusiness - error updating a business', { error })
        console.error(error)

        return false
      }
    }),

    updateBusiness: firestoreAction(async (_, business: IBusinessModel): Promise<boolean> => {
      console.debug('BusinessesStore - updateBusiness', { business })
      if (business === undefined) { return false }

      // Set the first image as main if there is no one
      if (
        business.attachments != undefined
        && typeof business.attachments === 'object'
        && Object.keys(business.attachments).length > 0
        && Object.values(business.attachments).find(a => a.isMain === true) === undefined
      ) {
        const first = Object.values(business.attachments)
          .sort((a,b) => a.createdTs - b.createdTs)[0]

        for (const k of Object.keys(business.attachments)) {
          business.attachments[k].isMain = k === first.id
        }
      }

      try {
        await businessesCollection.doc(business.id).update(business)
        console.debug('BusinessesStore - updateBusiness - success')

        return true
      } catch (error) {
        console.error('BusinessesStore - updateBusiness - error updating a business', { error })
        console.error(error)

        return false
      }
    }),

    createEvent: firestoreAction(async (_, event: IEventModel): Promise<boolean> => {
      console.debug('BusinessesStore - createEvent', event)
      if (event == undefined || event.businessId == undefined) { return false }

      if (event.id == undefined || event.id === 'new') {
        event.id = UUID()
      }

      try {
        const evt = event
        evt.reminderSent = moment.unix(event.createdTs).isSame(moment.unix(event.startTs), 'day') 
        || moment.unix(event.createdTs).add(1, 'day').isSame(moment.unix(event.startTs), 'day')
        await businessEventsCollection(evt.businessId).doc(evt.id).set(evt)
        console.debug('BusinessesStore - createEvent - success')

        return true
      } catch (error) {
        console.error('BusinessesStore - createEvent - error updating a business', { error })
        console.error(error)

        return false
      }
    }),

    updateEvent: firestoreAction(async (_, event: IEventModel): Promise<boolean> => {
      console.debug('BusinessesStore - updateEvent', { event })
      if (event == undefined || event.id == undefined || event.businessId == undefined) { return false }

      try {
        const evt = event
        evt.reminderSent = moment.unix(event.createdTs).isSame(moment.unix(event.startTs), 'day') 
        || moment.unix(event.createdTs).add(1, 'day').isSame(moment.unix(event.startTs), 'day')
        await businessEventsDocument(evt.businessId, evt.id).update(evt)
        console.debug('BusinessesStore - updateEvent - success')

        return true
      } catch (error) {
        console.error('BusinessesStore - updateEvent - error updating a business', { error })
        console.error(error)

        return false
      }
    }),

    deleteEvent: firestoreAction(async (_, event: IEventModel): Promise<boolean> => {
      console.debug('BusinessesStore - deleteEvent', event)
      if (event == undefined || event.businessId == undefined || event.id == undefined || event.id === 'new') {
        return false
      }

      try {
        await businessEventsCollection(event.businessId).doc(event.id).delete()
        console.debug('BusinessesStore - deleteEvent - success')

        return true
      } catch (error) {
        console.error('BusinessesStore - deleteEvent - error', { error })
        console.error(error)

        return false
      }
    }),

    getCurrentBusinessEventsInPeriod: firestoreAction(async ({ commit, state, rootState }, params: { from: Moment, to: Moment, source: 'manager' | 'admin' }): Promise<IEventModel[] | undefined> => {
      console.debug('BusinessesStore - getCurrentBusinessEventsInPeriod: ', { params })

      let businessId: string
      if (params.source === 'manager') {
        if ((rootState as IStoreState).auth.userBusiness == undefined || (rootState as IStoreState).auth.userBusiness!.id == undefined) {
          console.error('BusinessesStore - getCurrentBusinessEventsInPeriod - error getting the current business id')

          return undefined
        }

        businessId = (rootState as IStoreState).auth.userBusiness!.id
      } else if (params.source === 'admin') {
        if (state.business == undefined || state.business.id == undefined) {
          console.error('BusinessesStore - getCurrentBusinessEventsInPeriod - error getting the current business id')
          
          return
        }
        businessId = state.business.id
      } else {
        return
      }

      try {
        const result = await businessEventsCollection(businessId)
          .where('startTs', '<=', params.to.unix())
          .where('startTs', '>=', params.from.unix())
          .get()
        console.debug('BusinessesStore - getCurrentBusinessEventsInPeriod - result', result)

        if (result == undefined) { return undefined }
        const events = result.docs.map(d => d.data())
        commit('setBusinessEvents', { events })

        return events
      } catch (error) {
        console.error('BusinessesStore - getCurrentBusinessEventsInPeriod - error getting the business events in period', { error })
        console.error(error)

        return undefined
      }
    }),


    async exportAllBusinesses (_, { $t }): Promise<boolean> {
      console.debug('BusinessesStore - exportAllBusinesses')
      const fileName = `${moment().format('DD/MM/YYYY')}-businesses.xlsx`

      let businesses: IBusinessModel[] = []
      try {
        const queryResult = await businessesCollection.get()
        businesses = queryResult.docs.map(d => d.data() as IBusinessModel)
      } catch (error) {
        console.error('BusinessesStore - exportAllBusinesses - error getting the businesses')
        console.error(error)
        return false
      }
    
      console.debug('BusinessesStore - exportAllBusinesses - generate worksheet')
      try {
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
          [
            [$t('excel.businesses.collection'), $t('excel.businesses.businesses')],
            [$t('excel.businesses.export-date'), moment().format('DD/MM/YYYY')],
            ['']
          ]
        )
        XLSX.utils.sheet_add_json(
          ws,
          businesses.map(u => ({
            [$t('excel.businesses.id')]: u.id,
            [$t('excel.businesses.is-enabled')]: u.isEnabled ? 'True' : 'False',
            [$t('excel.businesses.type')]: BusinessTypeUtility.businessTypeHumanized(Number(u.type)),
            [$t('excel.businesses.owner-id')]: u.ownerId,
            [$t('excel.businesses.name')]: u.name,
            [$t('excel.businesses.email')]: u.email,
            [$t('excel.businesses.phone')]: u.phone,
            [$t('excel.businesses.steel-platforms')]: u.platforms && Object.keys(u.platforms).includes(PlatformType.STEEL.toLocaleString())
              ? u.platforms[PlatformType.STEEL]
              : 0,
            [$t('excel.businesses.soft-platforms')]: u.platforms && Object.keys(u.platforms).includes(PlatformType.SOFT.toLocaleString())
              ? u.platforms[PlatformType.SOFT]
              : 0,
            '': '',
            [$t('excel.businesses.description')]: u.description,
            [$t('excel.businesses.address')]: u.address ? u.address.formattedAddress : '',
            [$t('excel.businesses.subscription-id')]: u.subscription ? u.subscription.id : '',
            [$t('excel.businesses.subscription-plan-id')]: u.subscription ? u.subscription.planId : '',
            [$t('excel.businesses.subscription-status')]: u.subscription ? u.subscription.status : '',
            [$t('excel.businesses.subscription-created-date')]: u.subscription && u.subscription.start_date ? moment.unix(u.subscription.start_date).format('DD/MM/YYYY HH:mm:ss Z') : '',
            [$t('excel.businesses.subscription-first-billing-date')]: u.subscription && u.subscription.created ? moment.unix(u.subscription.created).format('DD/MM/YYYY HH:mm:ss Z') : '',
            [$t('excel.businesses.subscription-last-payment-date')]: u.subscription && u.subscription.current_period_start ? moment.unix(u.subscription.current_period_start).format('DD/MM/YYYY HH:mm:ss Z') : '',
            // [$t('excel.businesses.subscription-last-payment-amount')]: u.subscription ? u.subscription.lastPaymentAmount : '',
            [$t('excel.businesses.subscription-next-renewal-date')]: u.subscription && u.subscription.current_period_end ? moment.unix(u.subscription.current_period_end).format('DD/MM/YYYY HH:mm:ss Z') : '',
            [$t('excel.businesses.subscription-paid-through-date')]: u.subscription && u.subscription.current_period_end ? moment.unix(u.subscription.current_period_end).format('DD/MM/YYYY HH:mm:ss Z') : '',
            ' ': '',
            [$t('excel.businesses.created-date')]: u.createdTs != undefined && moment.unix(u.createdTs).isValid() ? moment.unix(u.createdTs).format('DD/MM/YYYY HH:mm:ss Z') : '',
            [$t('excel.businesses.last-periodical-review-date')]: u.lastPeriodicalReviewTs != undefined && moment.unix(u.lastPeriodicalReviewTs).isValid() ? moment.unix(u.lastPeriodicalReviewTs).format('DD/MM/YYYY HH:mm:ss Z') : '',
          })),
          { origin: -1 }
        )
    
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, $t('excel.businesses.businesses'))

        /* save to file */
        XLSX.writeFile(wb, fileName)
      } catch (error) {
        console.error('BusinessesStore - exportAllBusinesses - error generating the worksheet')
        console.error(error)
        return false
      }
      
      return true
    },
  }
}
