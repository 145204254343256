import { IFeedbackStoreState } from "@/interfaces/feedbacks-store-state.interface"
import { feedbacksIndex } from "@/utilities/algolia.utility"
import { feedbacksCollection, feedbacksDocument, userFeedbackCollection } from "@/utilities/firebase.utility"
import { IFeedbackModel } from "@wp-darts/shared"
import { Moment } from "moment"
import { Module } from "vuex"
import { firestoreAction, vuexfireMutations } from "vuexfire"


const hitsPerPage = 15

export const FeedbacksStore: Module<IFeedbackStoreState, unknown> = {
  namespaced: true,
  
  state: {
    feedback: undefined as IFeedbackModel | undefined,
    feedbacks: [] as IFeedbackModel[],
    feedbacksPage: 0,
    feedbacksPaginationEnd: false,
    feedbacksSearchQuery: '',
    feedbacksTotal: 0,
  },

  mutations: {
    ...vuexfireMutations,

    setFeedbacks (state, { feedbacks, page, end, total }) {
      console.log('FeedbacksStore - setFeedbacks', { feedbacks, page, end, total })
      state.feedbacks = feedbacks
      state.feedbacksPage = page
      state.feedbacksPaginationEnd = end
      state.feedbacksTotal = total
    },
    setFeedbacksSearchQuery (state, searchQuery) {
      state.feedbacksSearchQuery = searchQuery
    },
    resetFeedbacks (state, { feedbacks, page, total, end }) {
      console.log('FeedbacksStore - resetFeedbacks', { feedbacks, page, end, total })
      state.feedbacks = feedbacks
      state.feedbacksPage = page
      state.feedbacksPaginationEnd = end
      state.feedbacksTotal = total
    }
  },

  actions: {
    // Vuex mutations
    bindBusinessDocument: firestoreAction((context, id: string) => {
      console.debug('AuthenticationStore - bindFeedbacksDocument', { id })

      return context.bindFirestoreRef('feedback', feedbacksDocument(id))
    }),
    unbindBusinessDocument: firestoreAction(({ unbindFirestoreRef }) => {
      console.debug('AuthenticationStore - unbindUserRef')
      unbindFirestoreRef('feedback')
    }),

    async reloadFeedbacks (
      { rootState, commit },
      params?: {
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number
      }
    ): Promise<boolean> {
      console.log('FeedbacksStore - reloadFeedbacks', { params })
      
      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('FeedbacksStore - reloadFeedbacks - algolia clinet undefined')

        return false
      }

      try {
        const result = await feedbacksIndex((rootState as any).auth.algoliaClient).search(
          '',
          {
            hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
            facetFilters: params != undefined ? params.facetFilters : undefined,
            cacheable: false
          }
        )
        console.log('FeedbacksStore - result', { result })
        commit(
          'setFeedbacks',
          {
            page: 0,
            feedbacks: result.hits,
            end: result.hits.length < result.hitsPerPage,
            total: result.nbHits
          }
        )
      } catch (error) {
        console.error('FeedbacksStore - error searching inside the feedbacks index', { error })
        return false
      }

      return true
    },

    async paginateFeedbacks (
      { rootState, commit, state },
      params: { 
        forceReload?: boolean,
        query: string
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number,
        sortField?: 'createdTs' | 'type' | 'isEnabled' | 'checkTs' | 'hasOwner' | 'name',
        sortDirection?: 'asc' | 'desc'
      }
    ): Promise<void> {
      console.log('FeedbacksStore - paginateFeedbacks', params)
      commit('setFeedbacksSearchQuery', params.query)

      const page = params.query === state.feedbacksSearchQuery && !params.forceReload
        ? state.feedbacksPage + 1
        : 0

      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('FeedbacksStore - paginateFeedbacks - algolia clinet undefined')
        return
      }

      if (params.forceReload !== true && state.feedbacksPaginationEnd) {
        console.debug('FeedbacksStore - paginateFeedbacks - pagination ended')
        return
      }
  
      try {
        const result = await feedbacksIndex((rootState as any).auth.algoliaClient)
          .search(
            params.query,
            {
              page,
              // eslint-disable-next-line eqeqeq
              hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
              // eslint-disable-next-line eqeqeq
              facetFilters: params != undefined ? params.facetFilters : undefined,
              cacheable: params == undefined || params.forceReload !== true
            }
          )
        console.log('FeedbacksStore - paginateFeedbacks - result', result)
        commit(
          'setFeedbacks',
          {
            page,
            feedbacks: params.forceReload === true ? result.hits : [...state.feedbacks, ...result.hits],
            end: result.hits.length < result.hitsPerPage,
            total: result.nbHits
          }
        )
      } catch (error) {
        console.error('FeedbacksStore - error searching inside the feedbacks index', { params, error })
      }
    },

    getFeedback: firestoreAction(async ({ commit }, id: string): Promise<IFeedbackModel | undefined> => {
      console.debug('getFeedback', { id })

      try {
        const result = (await feedbacksCollection.doc(id).get()).data()
       
        console.log('FeedbacksStore - getFeedback - result', result)

        if (result == undefined) { return undefined }

        const feedback = result as IFeedbackModel
        commit('setFeedback', { feedback: feedback })

        return feedback
      } catch (error) {
        console.error('FeedbacksStore - getFeedback - error getting a feedbacks ', { id, error })
        console.error(error)

        return undefined
      }
    }),

    updateFeedbackNote: firestoreAction(async (context, params: { feedbackId: string, userId: string, note: string }): Promise<boolean> => {
      console.debug('FeedbacksStore - updateFeedbackNote', { params })
      if (params === undefined) { return false }

      try {
        userFeedbackCollection(params.userId).doc(params.feedbackId).update({ note: params.note })

        console.debug('RequestsStore - updateRequest - success')

        return true
      } catch (error) {
        console.error('RequestsStore - updateRequest - error updating a request', { error })
        console.error(error)

        return false
      }
    }),

    updateFeedbackReadTs: firestoreAction(async (context, params: { feedbackId: string, userId: string, readTs: Moment }): Promise<boolean> => {
      console.debug('FeedbacksStore - updateFeedbackReadTs', { params })
      if (params === undefined) { return false }

      try {
        userFeedbackCollection(params.userId).doc(params.feedbackId).update({ readTs: params.readTs })

        console.debug('RequestsStore - updateRequest - success')

        return true
      } catch (error) {
        console.error('RequestsStore - updateRequest - error updating a request', { error })
        console.error(error)

        return false
      }
    })
  }
}
