import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* Theme variables */
import './assets/variables.css';

/* Google Maps */
import { apiKey } from '@/utilities';
import VueGoogleMaps from '@fawmi/vue-google-maps'

import './assets/tailwind.css'


/* Internationalitaion */
import { createI18n } from 'vue-i18n'
import * as it from '@/assets/i18n/it.json'
import * as en from '@/assets/i18n/en.json'
import moment from 'moment';

const i18n = createI18n({
  // something vue-i18n options here ...
  messages: { en, it },
  fallbackLocale: 'en', // set fallback locale
})

// Import moment locale file(s)
import 'moment/locale/it';

library.add(fas, far)

const app =
  createApp(App)
    .use(store)
    .use(VueGoogleMaps, {
      load: { 
        key: apiKey,
        libraries: "places"
      },
    })
    .use(router)
    .use(i18n)
    .component('font-awesome-icon', FontAwesomeIcon)

router.isReady().then(async () => {
  app.mount('#app');
  console.debug('main - application is ready');
  
  // Get the browser language
  const userLang = navigator.language || (navigator as any).userLanguage;

  console.debug(`main - user language - ${ userLang }`);
  i18n.global.locale = userLang
  
  // Set the locale
  moment.locale(userLang);
});
