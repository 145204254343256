import { Module } from 'vuex'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import { IAlgoliaUserModel, IUserModel, PlatformTypeUtility, StatusCode } from '@wp-darts/shared'
import { userDocument, usersIndex, deleteUserCallable, createNewLocationOwnerUserCallable, usersCollection } from '@/utilities'
import { IUsersStoreState } from '@/interfaces'
import moment from 'moment'
import * as XLSX from 'xlsx'

const hitsPerPage = 15

export const UsersStore: Module<IUsersStoreState, unknown> = {
  namespaced: true,

  // setup the reactive todos property
  state: {
    user: undefined as IUserModel | undefined,
    users: [] as IUserModel[],
    usersSearchQuery: '',
    usersPaginationEnd: false,
    usersPage: 0,
    usersTotal: 0,
  },

  mutations: {
    ...vuexfireMutations,

    setUsers (state, { users, page, end, total }) {
      console.log('UsersStore - setUsers', { users, page, end, total })
      state.users = users
      state.usersPage = page
      state.usersPaginationEnd = end
      state.usersTotal = total
    },
    setUsersSearchQuery (state, usersSearchQuery) {
      state.usersSearchQuery = usersSearchQuery
    },
    resetUsers (state, { users, page, end, total }) {
      state.usersSearchQuery = ''
      state.users = users
      state.usersPage = page
      state.usersPaginationEnd = end
      state.usersTotal = total
    }
  },

  actions: {
    async paginateUsers (
      { state, rootState, commit },
      params: {
        forceReload?: boolean,
        query: string
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number,
        sortField?: 'createdTs' | 'email',
        sortDirection?: 'asc' | 'desc'
      }
    ): Promise<any | undefined> {
      console.debug('UsersStore - paginateUsers', params)
      commit('setUsersSearchQuery', params.query)

      const page = params.query === state.usersSearchQuery && !params.forceReload
        ? state.usersPage + 1
        : 0

      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('UsersStore - paginateUsers - algolia clinet undefined')
        return
      }

      if (params.forceReload !== true && state.usersPaginationEnd) {
        console.debug('UsersStore - paginateUsers - pagination ended')
        return
      }

      try {
        const result = await usersIndex((rootState as any).auth.algoliaClient, params.sortField, params.sortDirection)
          .search(
            params.query,
            {
              page,
              // eslint-disable-next-line eqeqeq
              hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
              // eslint-disable-next-line eqeqeq
              facetFilters: params != undefined ? params.facetFilters : undefined,
              cacheable: params == undefined || params.forceReload !== true
            },            
          )
        console.log('UsersStore - paginateUsers - result', result)
        commit(
          'setUsers',
          {
            page,
            users: params.forceReload === true ? result.hits : [...state.users, ...result.hits],
            end: result.hits.length < result.hitsPerPage,
            total: result.nbHits
          }
        )

        return result
      } catch (error) {
        console.error('UsersStore - error searching inside the users index', { params, error })
      }

      return undefined
    },

    getUser: firestoreAction(async (_, id: string): Promise<IUserModel | undefined> => {
      console.debug('getUser', { id })

      try {
        const result = (await userDocument(id).get()).data()

        console.log('UsersStore - result', result)

        if (result == undefined) { return undefined }

        const user = result as IUserModel

        return user
      } catch (error) {
        console.error('UsersStore - getUser - error getting a users ', { id, error })
        console.error(error)

        return undefined
      }
    }),

    async instantUsers ({ rootState },
      params: {
        query?: string,
        hitsPerPage?: number,
        page?: number,
        filters?: string,
        facetFilters?: string | string[]
        numericFilters?: string | string[]
      }
    ): Promise<IAlgoliaUserModel[]> {
      console.debug('UsersStore - instantUsers', params)

      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('UsersStore - instantUsers - algolia clinet undefined')

        return []
      }

      try {
        const result = await usersIndex((rootState as any).auth.algoliaClient)
          .search(
            params.query || '',
            {
              hitsPerPage: hitsPerPage || 20,
              page: params.page || 0,
              cacheable: false,
              filters: params.filters,
              numericFilters: params.numericFilters,
              facetFilters: params.facetFilters
            }
          )
        console.log('UsersStore - instantUsers - result', result)
       
        return result.hits as IAlgoliaUserModel[]
      } catch (error) {
        console.error('UsersStore - error searching inside the users index', { params, error })

        return []
      }
    },

    async createNewLocationOwnerUser (_,
      params: {
        name: string,
        surname: string,
        email: string,
        password: string
        businessId: string
      }
    ): Promise<Partial<IUserModel> | undefined> {
      console.debug('UsersStore - createNewLocationOwnerUser', { params: { ...params, password: 'hidden' }})

      // Function User Callable
      try {
        const result = await createNewLocationOwnerUserCallable(params)
        console.debug('UsersStore - createNewLocationOwnerUser - result', { result })

        if (!result || result.data.code !== StatusCode.OK || result.data.user == undefined) {
          return undefined
        }

        return result.data.user as Partial<IUserModel>
      } catch (error) {
        console.debug('UsersStore - createNewLocationOwnerUser - error', { error })
        console.debug(error)

        return undefined
      }
    },

    updateUser: firestoreAction(async (_, user: IUserModel): Promise<boolean> => {
      console.debug('UsersStore - updateUser', { user })
      if (user === undefined || user.id === undefined) { return false }

      try {
        await userDocument(user.id).update(user)
        console.debug('UsersStore - updateUser - success')

        return true
      } catch (error) {
        console.error('UsersStore - updateUser - error updating a business', { error })
        console.error(error)

        return false
      }
    }),

    async deleteUser (_, id: string): Promise<boolean> {
      console.debug('UsersStore - deleteUser - result', { id })
  
      // Function User Callable
      try {
        const result = await deleteUserCallable({ id })
        console.debug('UsersStore - deleteUser - result', { result })

        if (!result || result.data.code !== StatusCode.OK) {
          return false
        }
      } catch (error) {
        console.debug('UsersStore - deleteUser - error', { error })
        console.debug(error)

        return false
      }

      return true
    },

    async exportAllUsers (_, { $t }): Promise<boolean> {
      console.debug('UsersStore - exportAllUsers')
      const fileName = `${moment().format('DD/MM/YYYY')}-users.xlsx`

      let users: IUserModel[] = []
      try {
        const queryResult = await usersCollection.get()
        users = queryResult.docs.map(d => d.data() as IUserModel)
      } catch (error) {
        console.error('UsersStore - exportAllUsers - error getting the users')
        console.error(error)
        return false
      }
    
      console.debug('UsersStore - exportAllUsers - generate worksheet')
      try {
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(
          [
            [$t('excel.users.collection'), $t('excel.users.users')],
            [$t('excel.users.export-date'), moment().format('DD/MM/YYYY')],
            ['']
          ]
        )
        XLSX.utils.sheet_add_json(
          ws,
          users.map(u => ({
            [$t('excel.users.id')]: u.id,
            [$t('excel.users.role')]: u.role,
            [$t('excel.users.is-enabled')]: u.isEnabled ? 'True' : 'False',
            [$t('excel.users.name')]: u.name,
            [$t('excel.users.surname')]: u.surname,
            [$t('excel.users.display-name')]: u.displayName,
            [$t('excel.users.email')]: u.email,
            [$t('excel.users.preferred-language')]: u.preferredLanguage,
            [$t('excel.users.preferred-timezone')]: u.preferredTimezone,
            [$t('excel.users.preferred-platforms')]: u.preferredPlatformTypes != undefined ? PlatformTypeUtility.platformTypeHumanized(Number(u.preferredPlatformTypes)) : '',
            [$t('excel.users.preferred-addresses')]: u.preferredAddresses != undefined && typeof u.preferredAddresses === 'object' && Array.isArray(u.preferredAddresses)
              ? u.preferredAddresses.map(pa => pa.formattedAddress).join(',')
              : '',
            [$t('excel.users.created-date')]: u.createdTs != undefined && moment.unix(u.createdTs).isValid() ? moment.unix(u.createdTs).format('DD/MM/YYYY HH:mm:ss Z') : '',
            [$t('excel.users.updated-date')]: u.updatedTs != undefined && moment.unix(u.updatedTs).isValid() ? moment.unix(u.updatedTs).format('DD/MM/YYYY HH:mm:ss Z') : '',
            [$t('excel.users.deleted-date')]: u.deletedTs != undefined && moment.unix(u.deletedTs).isValid() ? moment.unix(u.deletedTs).format('DD/MM/YYYY HH:mm:ss Z') : '',
          })),
          { origin: -1 }
        )
    
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, $t('excel.users.users'))

        /* save to file */
        XLSX.writeFile(wb, fileName)
      } catch (error) {
        console.error('UsersStore - exportAllUsers - error generating the worksheet')
        console.error(error)
        return false
      }
      
      return true
    },
  }
}
