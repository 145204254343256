import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_alert_component = _resolveComponent("alert-component")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view),
    _createVNode(_TransitionGroup, {
      class: "alerts w-full h-full",
      name: "list",
      tag: "div"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.alerts, (alert) => {
          return (_openBlock(), _createBlock(_component_alert_component, {
            class: "alert",
            key: alert.ts,
            data: alert
          }, null, 8, ["data"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}