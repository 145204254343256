import { SearchClient } from 'algoliasearch'

// Define the indexes
export const usersIndex = (
  client: SearchClient,
  sortField?: 'createdTs' | 'email',
  sortDirection?: 'asc' | 'desc'
) => {
  const indexPrefix = process.env.NODE_ENV === 'production' ? 'prod_users' : 'dev_users'

  switch (sortField) {
    case 'email': return client.initIndex(`${indexPrefix}_email_${sortDirection ? sortDirection : 'desc'}`)
    case 'createdTs':
    default:
      return client.initIndex(`${indexPrefix}${sortDirection === undefined || sortDirection === 'desc' ? '' : '_asc'}`)
  }
}
export const businessesIndex  = (
  client: SearchClient,
  sortField?: 'createdTs' | 'type' | 'isEnabled' | 'checkTs' | 'name' | 'hasOwner',
  sortDirection?: 'asc' | 'desc'
) => {
  const indexPrefix = process.env.NODE_ENV === 'production' ? 'prod_locations' : 'dev_locations'

  switch (sortField) {
    case 'type': return client.initIndex(`${indexPrefix}_type_${sortDirection ? sortDirection : 'desc'}`)
    case 'isEnabled': return client.initIndex(`${indexPrefix}_enabled_${sortDirection ? sortDirection : 'desc'}`)
    case 'checkTs': return client.initIndex(`${indexPrefix}_check_${sortDirection ? sortDirection : 'desc'}`)
    case 'hasOwner': return client.initIndex(`${indexPrefix}_owned_${sortDirection ? sortDirection : 'desc'}`)
    case 'createdTs': return client.initIndex(`${indexPrefix}_creation_${sortDirection ? sortDirection : 'desc'}`)
    case 'name':
    default:
      return client.initIndex(`${indexPrefix}${sortDirection === undefined || sortDirection === 'asc' ? '' : '_desc'}`)
  }
}
export const requestsIndex = (client: SearchClient) => client.initIndex(process.env.NODE_ENV === 'production' ? 'prod_requests' : 'dev_requests')
export const feedbacksIndex = (client: SearchClient) => client.initIndex(process.env.NODE_ENV === 'production' ? 'prod_feedbacks' : 'dev_feedbacks')
