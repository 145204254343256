import { IRequestsStoreState } from "@/interfaces/requests-store-state.interface"
import { businessDocument, db, requestsDocument, requestsIndex } from "@/utilities"
import { BusinessType, IBusinessModel, IRequestModel, PlaceType } from "@wp-darts/shared"
import { businessesCollection, requestsCollection, userRequestCollection } from "@/utilities"
import { Module } from "vuex"
import { v4 as UUID } from 'uuid'
import { firestoreAction, vuexfireMutations } from 'vuexfire'
import moment from "moment"

const hitsPerPage = 15

export const RequestsStore: Module<IRequestsStoreState, unknown> = {
  namespaced: true,
  
  state: {
    request: undefined as IRequestModel | undefined,
    requests: [] as IRequestModel[],
    requestsSearchQuery: '',
    requestPaginationEnd: false,
    requestsPage: 0
  },

  mutations: {
    ...vuexfireMutations,

    setRequests (state, { requests, page, end }) {
      console.log('RequestsStore - setRequests', { requests, page, end })
      state.requests = requests
      state.requestsPage = page
      state.requestPaginationEnd = end
    },
    setRequestsSearchQuery (state, searchQuery) {
      state.requestsSearchQuery = searchQuery
    },
    resetRequests (state, { requests, page, end }) {
      console.log('RequestsStore - setRequests', { requests, page, end })
      state.requests = requests
      state.requestsPage = page
      state.requestPaginationEnd = end
    }
  },

  actions: {
    async reloadRequests (
      { rootState, commit },
      params?: {
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number
      }
    ): Promise<boolean> {
      console.log('RequestsStore - reloadRequests', { params })
  
      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('RequestsStore - reloadRequests - algolia clinet undefined')

        return false
      }

      try {
        const result = await requestsIndex((rootState as any).auth.algoliaClient).search(
          '',
          {
            // eslint-disable-next-line eqeqeq
            hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
            // eslint-disable-next-line eqeqeq
            facetFilters: params != undefined ? params.facetFilters : undefined,
            cacheable: false
          }
        )
        console.log('RequestsStore - result', { result })
        commit('resetRequests', result.hits)
      } catch (error) {
        console.error('RequestsStore - error searching inside the businesses index', { error })
        return false
      }

      return true
    },

    async paginateRequests (
      { rootState, commit, state },
      params: {
        forceReload?: boolean,
        query: string
        facetFilters?: string | readonly string[] | readonly (readonly string[])[] | undefined,
        hitsPerPage?: number,
        sortField?: 'createdTs' | 'type' | 'isEnabled' | 'checkTs' | 'hasOwner' | 'name',
        sortDirection?: 'asc' | 'desc'
      }
    ): Promise<void> {
      console.log('RequestsStore - paginateRequests', params)
      commit('setRequestsSearchQuery', params.query)

      const page = params.query === state.requestsSearchQuery && !params.forceReload
        ? state.requestsPage + 1
        : 0

      if ((rootState as any).auth == undefined || (rootState as any).auth.algoliaClient == undefined) {
        console.debug('RequestsStore - paginateRequests - algolia clinet undefined')
        return
      }

      if (params.forceReload !== true && state.requestPaginationEnd) {
        console.debug('RequestsStore - paginateRequests - pagination ended')
        return
      }
      
      try {
        const result = await requestsIndex((rootState as any).auth.algoliaClient)
          .search(
            params.query,
            {
              page,
              // eslint-disable-next-line eqeqeq
              hitsPerPage: params != undefined && params.hitsPerPage != undefined ? params.hitsPerPage : hitsPerPage,
              // eslint-disable-next-line eqeqeq
              facetFilters: params != undefined ? params.facetFilters : undefined,
              cacheable: params == undefined || params.forceReload !== true
            }
          )
        console.log('RequestsStore - paginateRequests - result', result)
        commit(
          'setRequests',
          {
            page,
            requests: params.forceReload === true ? result.hits : [...state.requests, ...result.hits],
            end: result.hits.length < result.hitsPerPage
          }
        )
      } catch (error) {
        console.error('RequestsStore - error searching inside the requests index', { params, error })
      }
    },

    createBusiness: firestoreAction(async (_, business: Partial<IBusinessModel>): Promise<string | undefined> => {
      console.debug('BusinessesStore - createBusiness', business)

      business.id = UUID()
      business.type = BusinessType.BASIC
      business.isEnabled = true

      try {
        await businessesCollection.doc(business.id).set(business as any)
        console.debug('BusinessesStore - createBusiness - success')

        return business.id
      } catch (error) {
        console.error('BusinessesStore - createBusiness - error updating a business', { error })
        console.error(error)

        return undefined
      }
    }),

    getRequest: firestoreAction(async ({ commit }, id: string): Promise<IRequestModel | undefined> => {
      console.debug('getRequest', { id })

      try {
        const result = (await requestsCollection.doc(id).get()).data()
        console.log('RequestsStore - getRequest - result', result)

        if (result == undefined) { return undefined }

        const request = result as IRequestModel
        commit('setRequest', { request: request })

        return request
      } catch (error) {
        console.error('RequestsStore - getRequest - error getting a requests ', { id, error })
        console.error(error)

        return undefined
      }
    }),

    approveRequest: firestoreAction(async (_, request: IRequestModel): Promise<boolean> => {
      console.debug('RequestsStore - approveRequest', request)
      if (request == undefined || request.newBusiness == undefined) {
        return false
      }

      const writer = db.batch()

      const now = moment()
      request.newBusiness.id = UUID()
      request.newBusiness.createdTs = now.unix()
      request.newBusiness.createdDate = now.format()
      request.newBusiness.isEnabled = true
      request.newBusiness.type = BusinessType.BASIC
      request.newBusiness.lastPeriodicalReviewTs = now.unix()
 
      if (request.newBusiness.placeType != undefined && request.newBusiness.placeType == PlaceType.SHOP) {
        request.newBusiness.isSellingDartsArticles = true
      }
 
      if (request.source === 'manager') {
        request.newBusiness.ownerId = request.userId
      }

      writer.update(
        requestsDocument(request.userId, request.id),
        { isApproved: request.isApproved, isArchived: request.isArchived }
      )

      writer.set(
        businessDocument(request.newBusiness.id),
        request.newBusiness
      )

      try {
        await writer.commit()
        console.debug('RequestsStore - updateRequest - success')

        return true
      } catch (error) {
        console.error('RequestsStore - updateRequest - error updating a request', { error })
        console.error(error)

        return false
      }
    }),


    // MARK: Request
    async sendRequest({ rootState }, request: IRequestModel): Promise<boolean> {
      if ((rootState as any).auth.authUser == undefined || request == undefined) {
        return false
      }
      try {
        await requestsDocument((rootState as any).auth.authUser.uid, request.id).set({ source: 'manager', ...JSON.parse(JSON.stringify(request)) })
        return true
      } catch (error) {
        console.debug('AuthenticationStore - sendRequest - error', { error })
        console.debug(error)
        return false
      }
    },

    updateArchivedStatus: firestoreAction(async (_, request: IRequestModel): Promise<boolean> => {
      console.debug('RequestsStore - updateRequest', { request })
      if (request === undefined) { return false }
      try {
        userRequestCollection(request.userId).doc(request.id).update({ isArchived: request.isArchived })

        console.debug('RequestsStore - updateRequest - success')

        return true
      } catch (error) {
        console.error('RequestsStore - updateRequest - error updating a request', { error })
        console.error(error)

        return false
      }
    }),
  }
}
1
