import { IStoreState } from '@/interfaces/store-state.interface'
import { createStore } from 'vuex'
import { vuexfireMutations } from 'vuexfire'
import { AuthenticationStore } from './authentication.store'
import { RequestsStore } from './requests.store'
import { BusinessesStore } from './businesses.store'
import { UsersStore } from './users.store'
import { FeedbacksStore } from './feedbacks.store'
import { AlertsStore } from './alerts'

export default createStore<IStoreState>({
  modules: {
    alerts: AlertsStore,
    auth: AuthenticationStore,
    businesses: BusinessesStore,
    feedbacks: FeedbacksStore,
    requests: RequestsStore,
    users: UsersStore,
  },

  mutations: vuexfireMutations
})
