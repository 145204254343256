import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a4f366a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "alert w-full" }
const _hoisted_2 = ["d"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["px-6 py-4 mx-2 my-4 rounded-md text-lg flex items-center w-full sm:w-1/3 xl:w-1/4", _ctx.backgroundClass])
    }, [
      (_openBlock(), _createElementBlock("svg", {
        class: _normalizeClass(["w-5 h-5 mr-3 sm:w-5 sm:h-5", _ctx.textClass]),
        viewBox: "0 0 24 24"
      }, [
        _createElementVNode("path", {
          fill: "currentColor",
          d: _ctx.icon
        }, null, 8, _hoisted_2)
      ], 2)),
      _createElementVNode("span", {
        class: "text-green-800",
        innerHTML: _ctx.data.message
      }, null, 8, _hoisted_3)
    ], 2)
  ]))
}